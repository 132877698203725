@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600&display=swap');

@font-face {
  font-family: 'BAG Regular';
  src: url('./assets/BAG-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'BAG Italic';
  src: url('./assets/BAG-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'BAG Light';
  src: url('./assets/BAG-Light.otf') format('opentype');
}

.App {
  display: block;
  margin: 6vh 3vw;
  position: relative;
  font-family: 'BAG Regular', 'Inter', sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  color: #000;
}

header {
  font-family: 'BAG Italic', 'Inter', sans-serif;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: underline;
}

a:hover {
  cursor: pointer;
  color: #f0414a;
}

ul, p {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

.color {
  color: #f0414a;
}

.section, .work  {
  margin: 5vh 0;
  padding: 0;
}

.section {
  width: 35%;
}

.work {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.name {
  text-transform: uppercase;
  margin: 0.5vh 0;
}

.field {
  height: auto;
  overflow: hidden;
}

.field > ul > li {
  margin: 0.2vh 0;
}

.small {
  flex-basis: 5%;
}

@media screen and (max-width: 800px) {


  .field {
    height: auto;
  }

  .field > * > img {
    width: 100%;
  }

  .section {
    width: 100%;
  }
}


